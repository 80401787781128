import { dateFormatBr, nomeMes } from "../../../utils/dateFunctions"
import { formatDecimalZ, formatPercentual, groupBy } from "../../../utils/functions"

export default function ConsumoPorDia(props: any) {
    const { empresas, mesSelected, consumoPorDia, dias, movimentoMes } = props
    // const dias = extrairDiasUnicos(consumoPorDia)
    const totalPorDia = groupBy(consumoPorDia, ['empresaId', 'ano', 'mes', 'dia'], ['qtd', 'consumo'])
    // console.log('dias', dias)
    // console.log('totalPorDia', totalPorDia)
    const totalConsumo = totalPorDia.reduce((acc: number, curr: any) => acc + curr.consumo, 0)
    const totalPerdas = movimentoMes.reduce((acc: number, curr: any) => acc + curr.quebra, 0)
    // console.log('totalConsumo', totalConsumo)
    // console.log('consumoPorDia', consumoPorDia)

    return (
        <div id="printableModal" className="">
            <div className="text-center">
                <div>
                    {/* TITULO */}
                    <h1>Ordem Produção diária Não Inflamável - Consumo Cloreto</h1>
                    <div className="linha-titulo flex flex-wrap text-center mb-2.5 font-semibold">
                        <h2 className="flex">
                            <div className="w-24 text-left">Empresa:</div>
                            <div className="w-96 text-left">{empresas[mesSelected?.empresaId - 1]?.nome}</div>
                        </h2>
                        <h2 className="flex">
                            <div className="w-14 text-left">Mês:</div>
                            <div className="w-60 text-left">{nomeMes(mesSelected?.mes)}/{mesSelected?.ano}</div>
                        </h2>
                    </div>

                    <div className="overflow-x-auto">
                        {/* CABECALHO */}
                        <div className="flex min-w-[890px] font-semibold border-0 border-b border-solid border-zinc-700 bg-gray-900 text-white"
                            key={"modcab"}
                        >
                            {/* <div className="w-10      px-2 py-1.5 text-left">It</div> */}
                            <div className="w-60      px-2 py-1.5 text-left">Dia</div>
                            <div className="w-[84px]  px-2 py-1.5 text-left">Codigo</div>
                            <div className="w-12      px-2 py-1.5 text-left">Un</div>
                            <div className="w-14      px-2 py-1.5 text-right">Qtd</div>
                            <div className="w-28      px-2 py-1.5 text-right">Utilização</div>
                        </div>
                        {/* CORPO */}
                        <div className="xmax-h-[1350px] min-w-[890px] overflow-y-autox">
                            {dias.map((dia: any, i: number) => {
                                const total = totalPorDia.find((x: any) => x.dia === dia.dia)
                                return (
                                    <>
                                        <div className="font-semibold px-2 py-1 bg-slate-300 text-left">{dateFormatBr(dia.data)} </div>
                                        {(consumoPorDia || []).map((x: any, i: number) => {
                                            if (x.dia === dia.dia) {
                                                return (
                                                    <div key={x.i} className="flex border-solid border-0 border-b border-[#b0d2ff]">
                                                        <div className="w-60      px-2 py-1 text-left"></div>
                                                        <div className="w-[84px]  px-2 py-1 text-left">{x.codigo}</div>
                                                        <div className="w-12      px-2 py-1 text-left">{x.un}</div>
                                                        <div className="w-14      px-2 py-1 text-right">{x.qtd}</div>
                                                        <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(x.consumo, 3)}</div>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                        <div className="font-semibold flex">
                                            <div className="w-[428px]      px-2 py-1 text-right">Total do Dia</div>
                                            <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(total.consumo, 3)}</div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>

                    {/* TOTAL DO MES */}
                    <div className="flex font-semibold my-2 bg-slate-300">
                        <div className="w-[428px]      px-2 py-1 text-right">Total Utilizado no Mês</div>
                        <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalConsumo, 3)}</div>
                    </div>
                    <div className="flex font-semibold my-2 bg-slate-300">
                        <div className="w-[428px]      px-2 py-1 text-right">Total de Perdas do Mês</div>
                        <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalPerdas, 3)}</div>
                        <div className="w-28      px-2 py-1 text-right">{formatPercentual(totalPerdas / (totalConsumo + totalPerdas), 3)}</div>
                    </div>
                    <div className="flex font-semibold my-2 bg-slate-300">
                        <div className="w-[428px]      px-2 py-1 text-right">Total do Mês</div>
                        <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalConsumo + totalPerdas, 3)}</div>
                    </div>
                </div>
            </div>
        </div>)
}
function extrairDiasUnicos(array: any[]): number[] {
    const dias = array.map(item => item.dia);
    const diasUnicos = Array.from(new Set(dias));
    return diasUnicos.sort((a, b) => a - b);
}
