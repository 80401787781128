
import * as React from "react"
import { useEffect, useState } from "react"

import { Divider, Typography } from "antd";

import Layout from "../../../components/template/Layout"
import { useAuth } from "../../../contexts/auth";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGeral } from "../../../contexts/geral";
import { customMessage } from "../../../components/template/CustomMessage";
import { apierp } from "../../../services/api";
import SpinnerNew from "../../../components/template/SpinnerNew";
import { formatDecimal, groupBy } from "../../../utils/functions";
import { dateFormat } from "../../../utils/dateFunctions";
import SpinnerModal from "../../../components/template/SpinnerModal";
import ModalConsumoOP from "./modalConsumoOP";

const { Title, Text, Paragraph } = Typography;

export default function RelCloretoPF() {
  const { Logout, user } = useAuth();
  const usuario: any = user
  const [innerW, innerH] = useWindowSize();

  const { userMenu, menuSelected, userTenant, } = useGeral();
  const menu = userMenu.find((x: any) => x.id == menuSelected)

  const [toggleState, setToggleState] = useState(false)
  const [loadingRelCloretoPF, setLoadingRelCloretoPF] = useState(false)
  const [relcloretopf, setRelCloretoPF] = useState([])
  const [estEmProdAcabados, setEstEmProdAcabados] = useState(0)
  const [saldoEmProdAcabados, setSaldoEmProdAcabados] = useState([])
  const [allconsumoop, setAllConsumoOP] = useState([])
  const [consumoOpMes, setConsumoOpMes] = useState([])
  const [movimentoMes, setMovimentoMes] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [showModalConsumoOP, setShowModalConsumoOP] = useState(false)
  const [mesSelected, setMesSelected] = useState(null)

  // useEffect( () => {
  //     try {
  //       loadEmpresas()
  //     } catch (err) {
  //       if (customMessage(err) === 403) Logout()
  //     }
  // }, [])

  useEffect(() => {
    setLoadingRelCloretoPF(true)
    setTimeout(async function () {
      try {
        await loadEmpresas()
        await loadRelCloretoPF()
      } catch (err) {
        if (customMessage(err) === 403) Logout()
      }
      setLoadingRelCloretoPF(false)
    }, 500);

  }, [])

  async function loadRelCloretoPF() {
    // console.log('loadRelCloretoPF', relcloretopf)
    const dados = {
      produtoId: 73, // CLORETO DE METILENO
    }
    const response = await apierp.post('relatorios/materiaprima', dados)
    const data = response.data as any;
    // console.log(data)
    setRelCloretoPF(data.relcloretopf);
    setEstEmProdAcabados(data.estEmProdAcabados); // DEPRECATED
    setAllConsumoOP(data.relconsumoop);
    setSaldoEmProdAcabados(data.saldoEmProdAcabados);
  }

  async function loadEmpresas() {
    // console.log('laodEmpresas')
    const response = await apierp.get('empresas')
    const data = response.data as any;
    // console.log(data)
    setEmpresas(data);
  }

  const hideModalConsumoOP = () => {
    setShowModalConsumoOP(false)
  }

  function handleClick(x: any) {
    // console.log('handleClick', x)
    const itens = allconsumoop.filter((y: any) => y.empresaId === x.empresaId && y.ano === x.ano && y.mes === x.mes)
    const movimentoMes = relcloretopf.filter((y: any) => y.empresaId === x.empresaId && y.ano === x.ano && y.mes === x.mes)
    // console.log('itens', itens)
    setMesSelected(x)
    setConsumoOpMes(itens)
    setMovimentoMes(movimentoMes)
    setShowModalConsumoOP(true)
  }

  // saldo em 31/12/2022
  // let saldoant = [15834, 7112]
  let saldoant = [16455.235, 7112]
  // let geralAnt = saldoant[0] + saldoant[1]
  // let geralAtual = saldoant[0] + saldoant[1] + estEmProdAcabados
  // let geralAtual = saldoant[0] + saldoant[1]
  const qtMeses = relcloretopf.length / 2
  const dadosrel: any = []
  const now = new Date();
  const mesHoje = dateFormat(now, "yyyy/MM")
  const diaHoje = +dateFormat(now, "dd")
  empresas.forEach((e: any, index) => {
    // console.log('emp', e.id, e.nome, relcloretopf)
    let sldant = 0; // saldoant[index]
    relcloretopf.filter((x: any) => x.empresaId === e.id).map((x: any, index) => {
      if (index === 0) {
        sldant = x.saldoinicial
      }
      let sldatu = sldant + x.compra - x.consumo - x.quebra - x.saidatransf + x.entradatransf + x.entradacompra - x.saidacompra
      const mesItem = `${x.ano}/${x.mes.toString().padStart(2, '0')}`
      // console.log(index, sldant, x.compra, x.consumo, sldatu)
      // console.log( mesItem, mesHoje, diaHoje)
      const item = {
        empresaId: x.empresaId,
        ano: x.ano,
        mes: x.mes,
        sldant: x.saldoinicial,
        compra: x.compra + x.entradacompra - x.saidacompra,
        consumo: x.consumo + x.quebra + x.saidatransf - x.entradatransf,
        sldatu: x.saldofinal,
        naoMostra: mesItem === mesHoje && diaHoje < 20 ? true : false,
        destaque: index === (qtMeses - 2) && diaHoje < 20 ? true : false
      }
      dadosrel.push(item)
      sldant = sldatu
    })

  })

  const wTab = 786
  const sldCon = groupBy(dadosrel, ['ano', 'mes'], ['sldatu'])
  // console.log('sldCon', sldCon)
  // console.log('saldoEmProdAcabados', saldoEmProdAcabados)

  return (
    <Layout titulo={"Relatório de Cloreto PF"} botoes={null}>
      <SpinnerModal loading={loadingRelCloretoPF} />
      <div className="w-full">
        <div className="overflow-x-auto">
          <div className="flex justify-center w-[1350px] mx-auto">
            {empresas.map((e: any, index) => {
              let totCompras = 0
              let totUtilizacao = 0
              let sldAtual = 0
              return (
                <div className="flex flex-col mb-4" >
                  <div className="mx-1">

                    {/* PARA CADA EMPRESA */}
                    <div className="flex justify-between">
                      <h2 className="mt-1">{e.nome}</h2>
                      <h2 hidden={index < empresas.length - 1} className="mt-1 mr-3">TOTAL</h2>
                    </div>
                    <div className="flex">
                      <div className="flex bg-zinc-500 px-3 pt-1 h-9 mb-0">
                        <h3 className="w-[60px]  text-white text-left ">Mês</h3>
                        <h3 className="w-[120px] text-white text-right ">Saldo Ant (KG)</h3>
                        <h3 className="w-[120px] text-white text-right ">Compras (KG)</h3>
                        <h3 className="w-[120px] text-white text-right ">Utilizacao (KG)</h3>
                        <h3 className="w-[135px] text-white text-right ">Saldo Atual (KG)</h3>
                      </div>
                      <div className="border-solid border-1 border-l border-white h-9 w-0 ml-2"></div>
                      {index < empresas.length - 1 ? (
                        null
                        // <div className="bg-black h-10 w-1 ml-2"></div>
                      ) : (
                        <div className="flex bg-zinc-500 px-3 pt-1 mb-0 h-9 ml-2">
                          <h3 className="w-[100px] text-white text-right ">Saldo Total</h3>
                        </div>
                      )}
                    </div>
                    {dadosrel.filter((dv: any) => dv.empresaId === e.id).map((x: any, ix: any) => {
                      // console.log('x', x)
                      const xx: any = saldoEmProdAcabados.find((y: any) => y.ano === x.ano && y.mes === x.mes)
                      // console.log('xx', xx)
                      totCompras += x.compra
                      totUtilizacao += x.consumo
                      sldAtual = x.sldatu
                      // console.log(sldAtual)
                      // geralAtual += (x.compra - x.consumo)
                      let cls = x.naoMostra ? 'text-zinc-200' : x.destaque ? 'text-red-700 font-bold' : ''
                      // console.log(x.naoMostra, cls)
                      return (
                        <div className="flex cursor-pointer hover:bg-zinc-100" onClick={() => { handleClick(x) }}>
                          <div className={`flex px-3 pt-1 border-solid border-0 border-b border-[#b0d2ff]`}>
                            <h4 className={`w-[60px]  text-left  ${cls}`}>{x.mes.toString().padStart(2, '0')}/{x.ano}</h4>
                            <h4 className={`w-[120px] text-right  ${cls}`} title={formatDecimal(x.sldant, 3)}>{formatDecimal(x.sldant, 0)}</h4>
                            <h4 className={`w-[120px] text-right  ${cls}`}>{formatDecimal(x.compra, 0)}</h4>
                            <h4 className={`w-[120px] text-right  ${cls} font-extrabold`} title={formatDecimal(x.consumo, 3)}>{formatDecimal(x.consumo, 0)}</h4>
                            <h4 className={`w-[135px] text-right  ${cls}`} title={formatDecimal(x.sldatu, 3)}>{formatDecimal(x.sldatu, 0)}</h4>
                          </div>
                          <div className="border-solid border-1 border-l border-zinc-300 h-9 w-1 ml-2"></div>
                          {index < empresas.length - 1 ? (
                            null
                          ) : (
                            <div className="px-3 pt-1 ml-2 bg-zinc-300 border-solid border-0 border-b border-[#84b9ff]">
                              <h4 className={`w-[100px] text-right  ${cls}`} title={`${formatDecimal(sldCon[ix].sldatu - xx?.saldoEmPaNaoVend, 3)} + ${formatDecimal(xx?.saldoEmPaNaoVend, 3)}`}>{formatDecimal(sldCon[ix].sldatu, 3)}</h4>
                            </div>
                          )}
                        </div>
                      )
                    })}
                    {/* <div className="flex px-3 pt-1 bg-slate-300 font-bold">
                    <h4 className="w-[60px] text-left ">Total...</h4>
                    <h4 className="w-[135px] text-right ">{formatDecimal(saldoant[index], 0)}</h4>
                    <h4 className="w-[135px] text-right ">{formatDecimal(totCompras, 0)}</h4>
                    <h4 className="w-[135px] text-right ">{formatDecimal(totUtilizacao, 0)}</h4>
                    <h4 className="w-[135px] text-right ">{formatDecimal(sldAtual, 0)}</h4>
                  </div> */}
                  </div>
                </div>
              )
            }
            )}

          </div>
          {/* <div className="flex flex-col mx-auto mb-4" style={{ maxWidth: `${668}px`, minWidth: `${668}px` }}>
          <div className="flex px-3 pt-1 mt-4 mx-4 bg-slate-300 font-bold">
            <h4 className="w-[60px] text-left "></h4>
            <h4 className="w-[135px] text-right "></h4>
            <h4 className="w-[300px] text-right ">SALDO EM PRODUTOS ACABADOS ...</h4>
            <h4 className="w-[135px] text-right ">{formatDecimal(estEmProdAcabados, 0)}</h4>
          </div>
          <div className="flex px-3 pt-1 mt-4 mx-4 bg-slate-400 font-bold">
            <h4 className="w-[60px] text-left "></h4>
            <h4 className="w-[135px] text-right "></h4>
            <h4 className="w-[135px] text-right "></h4>
            <h4 className="w-[135px] text-right ">SALDO CONJUNTO ...</h4>
            <h4 className="w-[135px] text-right ">{formatDecimal(geralAtual, 0)}</h4>
          </div>
        </div> */}
        </div>
      </div>
      <ModalConsumoOP showModalConsumoOP={showModalConsumoOP} hideModalConsumoOP={hideModalConsumoOP} consumoOpMes={consumoOpMes} mesSelected={mesSelected} empresas={empresas} movimentoMes={movimentoMes} />
    </Layout>
  )
}

