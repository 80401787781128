import { Button } from "antd"
import { formatDecimal } from "../../../utils/functions"
import { dateMySqlToPtBr } from "../../../utils/dateFunctions"

export default function CardPedidosPendentes(props: any) {
  const { pedido, empresas, rowIndex, rowSelected, handleRow } = props
  // const bg=rowIndex===rowSelected ? 'bg-purple-100' : pedido.dataSaidaEstoque ? 'bg-green-200' : 'bg-slate-100'
  // const bg = rowIndex === rowSelected ? 'bg-purple-100' : !pedido.dataSaidaEstoque ? 'bg-slate-100' : pedido.situacao === 'Atendido' ? 'bg-green-200' : 'bg-red-100'
  const bg = rowIndex === rowSelected ? 'bg-purple-100' : (pedido.tipoIntegracao && !pedido.notaNumero) ? 'bg-yellow-100' : !pedido.dataSaidaEstoque ? 'bg-slate-100' : pedido.situacao === 'Atendido' ? 'bg-green-200' : 'bg-red-100'
  const hideButton = (pedido.tipoIntegracao && !pedido.notaNumero) || pedido.dataSaidaEstoque
  let hideButtonx = false
  // console.log('pedido', pedido)
  const corDataSaidaEstoque = pedido.dataSaidaEstoque < pedido.data || pedido.dataSaidaEstoque < pedido.notaDataEmissao?.split(' ')[0]  ? 'text-red-600' : 'text-green-600'
  return (
    <div className="p-4">
      <div className={`w-[750px] ${bg}  rounded-lg shadow-gray-400 shadow-md  hover:shadow-gray-600 `}>
        <div className="flex items-center rounded-lg" >
          <div className="py-2 px-4 w-full">
            {!(pedido.situacao === 'Atendido')
              ? (<div className="mx-auto text-xl text-red-500 font-bold">PEDIDO CANCELADO APÓS PREPARAR ENTREGA E BAIXAR ESTOQUE!</div>)
              : (null)
            }
            {(pedido.tipoIntegracao && !pedido.notaNumero)
              ? (<div className="mx-auto text-xl text-red-500 font-bold">PEDIDO SEM NOTA FISCAL!</div>)
              : (null)
            }
            <div className="flex justify-left font-bold text-lg text-blue-600">
              <div className="w-[70px] ">Pedido:</div>
              <div className="w-[70px] ">{pedido.numero}</div>
              <div className="w-[105px]">{dateMySqlToPtBr(pedido.data)}</div>
              <div className="flex flex-1 justify-between ">
                <div className="">{empresas[pedido.empresaId]}</div>
                <div className="flex">Total: <div className="w-[100px] text-right">{formatDecimal(pedido.totalvenda, 2)}</div></div>
              </div>
            </div>
            <div className="flex justify-left font-bold text-lg text-blue-600">
              <div className="w-[70px]">NFe Nr:</div>
              <div className="w-[70px]">{pedido.notaNumero}</div>
              <div className="w-[105px]">{dateMySqlToPtBr(pedido.notaDataEmissao)}</div>
              <div className="flex flex-1 justify-between ">
                <div className="">Série: {pedido.notaSerie}</div>
                <div className="flex ">ValorNota: <div className="w-[100px] text-right">{formatDecimal(pedido.notaValorNota, 2)}</div></div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-semibold mt-2">Vendedor:  {pedido.vendedor}</div>
              <div className="font-semibold mt-2">Plataforma:  <span className='font-bold'>{pedido.tipoIntegracao ?? 'Venda Direta'}</span></div>
            </div>
            <div className="font-semibold mt-2">Cliente: cpf/cnpj: {pedido.cliente.cnpj} nome: {pedido.cliente.nome}</div>
            <div className="font-semibold">Cidade: {pedido.cliente.cidade}, {pedido.cliente.uf}</div>
            <div className="font-semibold">Transportadora: {pedido.nomeTransportadora}</div>
            <div className="font-semibold mt-2">Produtos:</div>
            {pedido.itens.map((it: any, index: any) => {
              // console.log(it)
              const cls = it.situacao !== 'Atendido' ? 'text-red-500' : ''
              const txt = it.situacao !== 'Atendido' ? '(Verificar Nota)' : ''
              if (it.situacao !== 'Atendido') {hideButtonx = true}
              return (
                <div key={index} className="flex justify-between font-medium">
                  <div className={cls}>{it.codigo} - {it.descricao}</div>
                  <div className="flex">
                    <div className={`${cls} mr-1 text-xs mt-1`}>{txt}</div>
                    <div className={cls}>qtd: {it.quantidade} {it.unidade}</div>
                  </div>
                </div>
              )
            }
            )}
            <div className="font-semibold mt-2">Embalagens para transporte:</div>
            {pedido.caixas.map((it: any, index: any) =>
              <div key={index} className="flex justify-between font-medium">
                <div className="" >{it.codigo} - {it.nomeComponente}</div>
                <div>qtd: {it.quantidade} {it.unidade}</div>
              </div>
            )}
            <Button onClick={() => handleRow(pedido.id, rowIndex)} type="primary" hidden={hideButton || hideButtonx} className="my-2 px-2 py-1 font-medium">Preparar Entrega</Button>
            <div className="font-bold mt-2 text-blue-600" hidden={!pedido.dataSaidaEstoque} >ENTREGA PREPARADA OU JÁ REALIZADA - ESTOQUE LANÇADO EM <span className={`text-lg ${corDataSaidaEstoque}`}>{dateMySqlToPtBr(pedido.dataSaidaEstoque)}</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}