import { Button, Modal, Switch } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { groupBy } from "../../../utils/functions";
import ConsumoPorSemana from "./consumoPorSemana";
import ConsumoPorDia from "./consumoPorDia";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

export default function ModalConsumoOP(props: any) {
    const { empresas, mesSelected, consumoOpMes, movimentoMes } = props
    // console.log('consumoOpMes', consumoOpMes)
    const semanasUnicas = extrairSemanasUnicas(consumoOpMes)
    const diasUnicos = extrairDiasUnicos(consumoOpMes)
    // console.log('semanasUnicas', semanasUnicas)

    let semanas: any = []
    semanasUnicas.forEach((wkoy: number) => {
        const itens = consumoOpMes.filter((x: any) => x.wkoy === wkoy)
        // console.log('wkoy', wkoy, itens[0].data, itens[itens.length-1].data)
        semanas.push({ wkoy, dataInicial: itens[0].data, dataFinal: itens[itens.length - 1].data })
    })
    let dias: any = []
    diasUnicos.forEach((dia: number) => {
        const itens = consumoOpMes.filter((x: any) => x.dia === dia)
        dias.push({ dia, data: itens[0].data })
    })
    // console.log('dias', dias)
    // console.log('semanas', semanas)
    const consumoPorMes = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'codigo', 'un'], ['qtd', 'consumo'])
    const consumoPorDia = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'dia', 'codigo', 'un'], ['qtd', 'consumo'])
    const consumoPorSemana = groupBy(consumoOpMes, ['empresaId', 'ano', 'mes', 'wkoy', 'codigo', 'un'], ['qtd', 'consumo'])

    // console.log('mes', mes)
    // console.log('dias', consumoPorDia)
    // console.log('semanas', consumoPorSemana)

    const documentTitle = `Consumo OP ${empresas[mesSelected?.empresaId - 1]?.nome} ${mesSelected?.mes}/${mesSelected?.ano}`
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef, documentTitle });
    const [isDiario, setIsDiario] = useState(true)
    const onChange = (checked: boolean) => {
        setIsDiario(checked)
    };
    return (
        <Modal className="max-h-[1120px]" title="Ordem de Produção Impermeabilizante Não Inflamável - Consumo Cloreto" open={props.showModalConsumoOP} onOk={props.hideModalConsumoOP} onCancel={props.hideModalConsumoOP} footer={[]} centered width={1040}>
            <div className="flex w-full justify-center">
                {/* selecionar dia ou mes */}
                <Switch className="bg-blue-600" checkedChildren="Diário" unCheckedChildren="Semanal" defaultChecked onChange={onChange} />
            </div>
            <div ref={contentRef} className="py-4 px-9">
                {isDiario ?
                    <ConsumoPorDia empresas={empresas} mesSelected={mesSelected} consumoPorDia={consumoPorDia} dias={dias} movimentoMes={movimentoMes} />
                    :
                    <ConsumoPorSemana empresas={empresas} mesSelected={mesSelected} consumoPorSemana={consumoPorSemana} semanas={semanas} movimentoMes={movimentoMes} />
                }
                {/* <ConsumoPorDia empresas={empresas} mesSelected={mesSelected} consumoPorDia={consumoPorDia} dias={dias} movimentoMes={movimentoMes} />
                <ConsumoPorSemana empresas={empresas} mesSelected={mesSelected} consumoPorSemana={consumoPorSemana} semanas={semanas} movimentoMes={movimentoMes} /> */}
            </div>
            <div className="flex w-full justify-center mt-2">
                {/* <Button onClick={() => printDiv2('printableModal')} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button> */}
                <Button onClick={() => reactToPrintFn()} shape="round" size="small" icon={<PrinterOutlined />} title="Imprimir"></Button>
            </div>
        </Modal>
    )
}

function extrairDiasUnicos(array: any[]): number[] {
    const dias = array.map(item => item.dia);
    const diasUnicos = Array.from(new Set(dias));
    return diasUnicos.sort((a, b) => a - b);
}

function extrairSemanasUnicas(array: any[]): number[] {
    const semanas = array.map(item => item.wkoy);
    const semanasUnicas = Array.from(new Set(semanas));
    return semanasUnicas.sort((a, b) => a - b);
}