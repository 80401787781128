import { Button, Space } from "antd"
import { CopyOutlined, PrinterOutlined } from "@ant-design/icons"
import { dateFormatBr, nomeMes } from "../../../utils/dateFunctions"
import { copyToClipboard, formatDecimalZ, formatPercentual, groupBy } from "../../../utils/functions"

export default function ConsumoPorSemana(props: any) {
    const { empresas, mesSelected, consumoPorSemana, semanas, movimentoMes } = props
    // const dias = extrairDiasUnicos(consumoPorDia)
    const totalPorSemana = groupBy(consumoPorSemana, ['empresaId', 'ano', 'mes', 'wkoy'], ['qtd', 'consumo'])
    // console.log('dias', dias)
    // console.log('totalPorDia', totalPorDia)
    // console.log('totalPorSemana', totalPorSemana)
    const totalConsumo = totalPorSemana.reduce((acc: number, curr: any) => acc + curr.consumo, 0)
    const totalPerdas = movimentoMes.reduce((acc: number, curr: any) => acc + curr.quebra, 0)

    // console.log('totalConsumo', totalConsumo)
    return (
        <>
            <div id="printableModal" className="">
                <div className="text-center">
                    <div>
                        {/* TITULO */}
                        <h1>Ordem Produção semanal Não Inflamável - Consumo Cloreto</h1>
                        <div className="linha-titulo flex flex-wrap text-center mb-2.5 font-semibold">
                            <h2 className="flex">
                                <div className="w-24 text-left">Empresa:</div>
                                <div className="w-96 text-left">{empresas[mesSelected?.empresaId - 1]?.nome}</div>
                            </h2>
                            <h2 className="flex">
                                <div className="w-14 text-left">Mês:</div>
                                <div className="w-60 text-left">{nomeMes(mesSelected?.mes)}/{mesSelected?.ano}</div>
                            </h2>
                        </div>

                        <div className="overflow-x-auto">
                            {/* CABECALHO */}
                            <div className="flex min-w-[900px] font-semibold border-0 border-b border-solid border-zinc-700 bg-gray-900 text-white"
                                key={"modcab"}
                            >
                                {/* <div className="w-10      px-2 py-1.5 text-left">It</div> */}
                                <div className="w-60      px-2 py-1.5 text-left">Semana</div>
                                <div className="w-[84px]  px-2 py-1.5 text-left">Codigo</div>
                                <div className="w-12      px-2 py-1.5 text-left">Un</div>
                                <div className="w-14      px-2 py-1.5 text-right">Qtd</div>
                                <div className="w-28      px-2 py-1.5 text-right">Utilização</div>
                            </div>
                            {/* CORPO */}
                            <div className="xmax-h-[1350px] min-w-[900px] overflow-y-autox">
                                {semanas.map((wkoy: any, i: number) => {
                                    const total = totalPorSemana.find((x: any) => x.wkoy === wkoy.wkoy)
                                    // console.log('total', total.consumo)
                                    return (
                                        <>
                                            <div className="font-semibold px-2 py-1 bg-slate-300 text-left" key={wkoy.wkoy}>{dateFormatBr(wkoy.dataInicial)} a {dateFormatBr(wkoy.dataFinal)}</div>
                                            {(consumoPorSemana || []).map((x: any, i: number) => {
                                                if (x.wkoy === wkoy.wkoy) {
                                                    return (
                                                        <div key={x.i} className="flex border-solid border-0 border-b border-[#b0d2ff]">
                                                            {/* <div className="w-10      px-2 py-1 text-left">{i + 1}</div> */}
                                                            <div className="w-60      px-2 py-1 text-left"></div>
                                                            <div className="w-[84px]  px-2 py-1 text-left">{x.codigo}</div>
                                                            <div className="w-12      px-2 py-1 text-left">{x.un}</div>
                                                            <div className="w-14      px-2 py-1 text-right">{x.qtd}</div>
                                                            <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(x.consumo, 3)}</div>
                                                        </div>
                                                    )
                                                }
                                                return null
                                            })}
                                            <div className="font-semibold flex">
                                                <div className="w-[428px]      px-2 py-1 text-right">Total da Semana</div>
                                                <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(total.consumo, 3)}</div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>

                            {/* TOTAL DO MES */}
                            <div className="flex font-semibold my-2 bg-slate-300">
                                <div className="w-[428px]      px-2 py-1 text-right">Total Utilizado no Mês</div>
                                <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalConsumo, 3)}</div>
                            </div>
                            <div className="flex font-semibold my-2 bg-slate-300">
                                <div className="w-[428px]      px-2 py-1 text-right">Total de Perdas do Mês</div>
                                <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalPerdas, 3)}</div>
                                <div className="w-28      px-2 py-1 text-right">{formatPercentual(totalPerdas / (totalConsumo + totalPerdas), 3)}</div>
                            </div>
                            <div className="flex font-semibold my-2 bg-slate-300">
                                <div className="w-[428px]      px-2 py-1 text-right">Total do Mês</div>
                                <div className="w-28      px-2 py-1 text-right">{formatDecimalZ(totalConsumo + totalPerdas, 3)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
}

//<div key={wkoy.wkoy}>{wkoy.wkoy} {wkoy.dataInicial} {wkoy.dataFinal}</div>
// { (consumoPorSemana || []).map((x: any, i: number) => {
//     return (
//         <div key={x.i} className="flex border-solid border-0 border-b border-[#b0d2ff]">
//             <div className="w-10      px-2 py-1.5 text-left">{i + 1}</div>
//             <div className="w-60      px-2 py-1.5 text-left">{x.wkoy}</div>
//             <div className="w-[84px]  px-2 py-1.5 text-left">{x.codigo}</div>
//             <div className="w-10      px-2 py-1.5 text-left">{x.un}</div>
//             <div className="w-12      px-2 py-1.5 text-right">{x.qtd}</div>
//             <div className="w-28      px-2 py-1.5 text-right">{x.consumo}</div>
//         </div>
//     )
// }
// )}